var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "login-component" } }, [
    _c("div", { staticClass: "login-window animated" }, [
      _c("div", { staticClass: "left-side " }, [
        _c("div", {
          staticClass: "component-loading",
          class: { show: _vm.loading }
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "left-side-login animated",
            class: { fadeOutLeft: !_vm.loginState, fadeInLeft: _vm.loginState }
          },
          [
            _vm.changePassword
              ? [
                  _vm.passSuccessChange
                    ? [
                        _c(
                          "div",
                          {
                            staticClass: "forgot-send-info animated zoomIn",
                            staticStyle: { "padding-bottom": "50px" }
                          },
                          [
                            _c("img", { attrs: { src: "/images/check.svg" } }),
                            _vm._v(" "),
                            _vm._m(0),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              { staticClass: "btn", attrs: { to: "/login/" } },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "svg-icon",
                                    attrs: { width: "14", height: "14" }
                                  },
                                  [
                                    _c("use", {
                                      attrs: {
                                        "xlink:href":
                                          "/images/sprite.svg#login-btn"
                                      }
                                    })
                                  ]
                                ),
                                _vm._v(" "),
                                _c("span", { staticClass: "btn_text" }, [
                                  _vm._v("Вход")
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    : [
                        _c(
                          "label",
                          { staticClass: "login-head-label chpass-label" },
                          [_vm._v("Смена пароля")]
                        ),
                        _vm._v(" "),
                        _c("form", { staticClass: "chpass-form" }, [
                          _c("input", {
                            attrs: {
                              type: "hidden",
                              name: "AUTH_ACTION",
                              value: "Изменить пароль"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "control_text",
                              class: { control_error: _vm.errors.login }
                            },
                            [
                              _c("label", [_vm._v("E-mail")]),
                              _vm._v(" "),
                              _c("input", {
                                attrs: {
                                  id: "login-input",
                                  type: "text",
                                  name: "USER_LOGIN",
                                  required: "",
                                  placeholder: "E-mail"
                                },
                                domProps: { value: _vm.login },
                                on: { input: _vm.checkFieldsChPass }
                              }),
                              _vm._v(" "),
                              _vm.errors.login
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "control_error_info animated heartBeat"
                                    },
                                    [_vm._v(_vm._s(_vm.errorText.login))]
                                  )
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "control_text",
                              class: { control_error: _vm.errors.checkword }
                            },
                            [
                              _c("label", [_vm._v("Контрольная строка")]),
                              _vm._v(" "),
                              _c("input", {
                                attrs: {
                                  id: "checkword-input",
                                  type: "text",
                                  name: "USER_CHECKWORD",
                                  required: "",
                                  placeholder: "Контрольная строка"
                                },
                                domProps: { value: _vm.checkword },
                                on: { input: _vm.checkFieldsChPass }
                              }),
                              _vm._v(" "),
                              _vm.errors.checkword
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "control_error_info animated heartBeat"
                                    },
                                    [_vm._v(_vm._s(_vm.errorText.checkword))]
                                  )
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "control_text",
                              class: { control_error: _vm.errors.new_password }
                            },
                            [
                              _c("label", [_vm._v("Пароль")]),
                              _vm._v(" "),
                              _vm.showPass.new_password
                                ? _c("input", {
                                    attrs: {
                                      id: "new_password-input",
                                      type: "text",
                                      name: "USER_PASSWORD",
                                      required: "",
                                      placeholder: "Пароль"
                                    },
                                    domProps: { value: _vm.new_password },
                                    on: { input: _vm.checkFieldsChPass }
                                  })
                                : _c("input", {
                                    attrs: {
                                      id: "new_password-input",
                                      type: "password",
                                      name: "USER_PASSWORD",
                                      required: "",
                                      placeholder: "Пароль"
                                    },
                                    domProps: { value: _vm.new_password },
                                    on: { input: _vm.checkFieldsChPass }
                                  }),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "pass-toggle",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      _vm.showPass.new_password = !_vm.showPass
                                        .new_password
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "svg",
                                    { attrs: { width: "14", height: "8" } },
                                    [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href": "/images/sprite.svg#eye"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm.errors.new_password
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "control_error_info animated heartBeat"
                                    },
                                    [_vm._v(_vm._s(_vm.errorText.new_password))]
                                  )
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "control_text",
                              class: {
                                control_error: _vm.errors.confirm_password
                              }
                            },
                            [
                              _c("label", [_vm._v("Подтверждение пароля")]),
                              _vm._v(" "),
                              _vm.showPass.confirm_password
                                ? _c("input", {
                                    attrs: {
                                      id: "confirm_password-input",
                                      type: "text",
                                      name: "USER_CONFIRM_PASSWORD",
                                      required: "",
                                      placeholder: "Подтверждение пароля"
                                    },
                                    domProps: { value: _vm.confirm_password },
                                    on: { input: _vm.checkFieldsChPass }
                                  })
                                : _c("input", {
                                    attrs: {
                                      id: "confirm_password-input",
                                      type: "password",
                                      name: "USER_CONFIRM_PASSWORD",
                                      required: "",
                                      placeholder: "Подтверждение пароля"
                                    },
                                    domProps: { value: _vm.confirm_password },
                                    on: { input: _vm.checkFieldsChPass }
                                  }),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "pass-toggle",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      _vm.showPass.confirm_password = !_vm
                                        .showPass.confirm_password
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "svg",
                                    { attrs: { width: "14", height: "8" } },
                                    [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href": "/images/sprite.svg#eye"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm.errors.confirm_password
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "control_error_info animated heartBeat"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.errorText.confirm_password)
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _vm.chPasswordError
                          ? _c("span", {
                              staticClass: "auth_error_info animated fadeIn",
                              domProps: {
                                innerHTML: _vm._s(_vm.chPasswordErrorText)
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "control_btn" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn",
                              on: { click: _vm.submitChangePass }
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "svg-icon",
                                  attrs: { width: "14", height: "14" }
                                },
                                [
                                  _c("use", {
                                    attrs: {
                                      "xlink:href":
                                        "/images/sprite.svg#login-btn"
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "btn_text" }, [
                                _vm._v("Поменять")
                              ])
                            ]
                          )
                        ])
                      ]
                ]
              : [
                  _vm._m(1),
                  _vm._v(" "),
                  _c("form", { staticClass: "login-form" }, [
                    _c(
                      "div",
                      {
                        staticClass: "control_text",
                        class: { control_error: _vm.errors.login }
                      },
                      [
                        _c("label", [_vm._v("E-mail")]),
                        _vm._v(" "),
                        _c("input", {
                          attrs: {
                            id: "login-input",
                            type: "text",
                            name: "login",
                            required: "",
                            placeholder: "E-mail"
                          },
                          on: {
                            input: _vm.checkFields,
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.submitLogin.apply(null, arguments)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm.errors.login
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "control_error_info animated heartBeat"
                              },
                              [_vm._v(_vm._s(_vm.errorText.login))]
                            )
                          : _vm._e()
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "control_text",
                        class: { control_error: _vm.errors.password }
                      },
                      [
                        _c("label", [_vm._v("Пароль")]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "remind-pass-link",
                            attrs: { href: "" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.loginState = false
                              }
                            }
                          },
                          [_vm._v("Забыли пароль?")]
                        ),
                        _vm._v(" "),
                        _vm.showPass.password
                          ? _c("input", {
                              attrs: {
                                id: "password-input",
                                type: "text",
                                name: "password",
                                required: "",
                                placeholder: "Пароль"
                              },
                              domProps: { value: _vm.password },
                              on: {
                                input: _vm.checkFields,
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.submitLogin.apply(null, arguments)
                                }
                              }
                            })
                          : _c("input", {
                              attrs: {
                                id: "password-input",
                                type: "password",
                                name: "password",
                                required: "",
                                placeholder: "Пароль"
                              },
                              domProps: { value: _vm.password },
                              on: {
                                input: _vm.checkFields,
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.submitLogin.apply(null, arguments)
                                }
                              }
                            }),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "pass-toggle",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.showPass.password = !_vm.showPass.password
                              }
                            }
                          },
                          [
                            _c("svg", { attrs: { width: "14", height: "8" } }, [
                              _c("use", {
                                attrs: {
                                  "xlink:href": "/images/sprite.svg#eye"
                                }
                              })
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _vm.errors.password
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "control_error_info animated heartBeat"
                              },
                              [_vm._v(_vm._s(_vm.errorText.password))]
                            )
                          : _vm._e()
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _vm.authError
                    ? _c("span", {
                        staticClass: "auth_error_info animated fadeIn",
                        domProps: { innerHTML: _vm._s(_vm.authErrorText) }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "control_btn" }, [
                    _c(
                      "button",
                      { staticClass: "btn", on: { click: _vm.submitLogin } },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "svg-icon",
                            attrs: { width: "14", height: "14" }
                          },
                          [
                            _c("use", {
                              attrs: {
                                "xlink:href": "/images/sprite.svg#login-btn"
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "btn_text" }, [
                          _vm._v("Войти")
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _vm._m(2)
                ]
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "left-side-forgot animated",
            class: {
              fadeInRight: !_vm.loginState,
              fadeOutRight: !!_vm.loginState
            }
          },
          [
            _c(
              "a",
              {
                staticClass: "back-link",
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.loginState = true
                  }
                }
              },
              [
                _c(
                  "svg",
                  {
                    staticClass: "svg-icon",
                    attrs: { width: "6", height: "12" }
                  },
                  [
                    _c("use", {
                      attrs: { "xlink:href": "/images/sprite.svg#back" }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "btn_text" }, [_vm._v("Назад")])
              ]
            ),
            _vm._v(" "),
            _vm.forgotSuccessSend
              ? [
                  _c(
                    "div",
                    { staticClass: "forgot-send-info animated zoomIn" },
                    [
                      _c("img", { attrs: { src: "/images/send.svg" } }),
                      _vm._v(" "),
                      _c("div", { staticClass: "login_description" }, [
                        _vm._m(3),
                        _vm._v(" "),
                        _c("b", [_vm._v(_vm._s(_vm.forgot))])
                      ]),
                      _vm._v(" "),
                      _c("button", { staticClass: "btn" }, [
                        _c(
                          "span",
                          {
                            staticClass: "btn_text",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.loginState = true
                              }
                            }
                          },
                          [_vm._v("Понятно")]
                        )
                      ])
                    ]
                  )
                ]
              : [
                  _vm._m(4),
                  _vm._v(" "),
                  _c("form", { staticClass: "login-form" }, [
                    _c(
                      "div",
                      {
                        staticClass: "control_text",
                        class: { control_error: _vm.errors.login }
                      },
                      [
                        _c("label", [_vm._v("E-mail")]),
                        _vm._v(" "),
                        _c("input", {
                          attrs: {
                            id: "forgot-login-input",
                            type: "text",
                            name: "forgot_login",
                            required: "",
                            placeholder: "E-mail"
                          },
                          on: { input: _vm.checkFields }
                        }),
                        _vm._v(" "),
                        _vm.errors.login
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "control_error_info animated heartBeat"
                              },
                              [_vm._v(_vm._s(_vm.errorText.login))]
                            )
                          : _vm._e()
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _vm.forgotError
                    ? _c("span", {
                        staticClass: "auth_error_info animated fadeIn",
                        domProps: { innerHTML: _vm._s(_vm.forgotErrorText) }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "control_btn" }, [
                    _c(
                      "button",
                      { staticClass: "btn", on: { click: _vm.submitForgot } },
                      [
                        _c("span", { staticClass: "btn_text" }, [
                          _vm._v("Воccтановить")
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _vm._m(5)
                ]
          ],
          2
        )
      ]),
      _vm._v(" "),
      _vm._m(6)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "login-head-label",
        staticStyle: { "text-align": "center" }
      },
      [_c("span", [_vm._v("Пароль успешно изменен"), _c("br"), _c("br")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "login-head-label" }, [
      _vm._v("Добро пожаловать"),
      _c("br"),
      _vm._v("в личный кабинет!")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "login_description" }, [
      _c("b", [
        _vm._v("Доступ в кабинет возможен только для действующих клиентов.")
      ]),
      _vm._v(" "),
      _c("span", [
        _vm._v(
          "Если Вы являетесь  постоянным клиентом компании Ферон, запросите данные доступа в личный кабинет у персонального менеджера."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("Данные для восстановления пароля будут"),
      _c("br"),
      _vm._v("отправлены на указанный E-mail")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "login-head-label" }, [
      _vm._v("Восстановление"),
      _c("br"),
      _vm._v("пароля")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "login_description" }, [
      _c("span", [
        _vm._v("Данные для восстановления пароля будут"),
        _c("br"),
        _vm._v("отправлены на указанный E-mail")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "right-side" }, [
      _c("img", {
        staticClass: "login-logo",
        attrs: { src: "/images/white-logo.png" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }