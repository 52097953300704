<template>
	<div>
		<div class="environ">
			<div v-bind:class="{'hidden': $root.arUser.profiles.count == 1}" class="environ__group">
				<label class="environ__label">Активный договор</label>
				<div class="environ__select environ__select--order">
					<select class="select-profile select-profile-home select" name="">
						<option
							v-for="profile in $root.arUser.profiles.list"
							v-bind:value="profile.id"
							v-bind:class="{'active': profile.active}"
							v-bind:selected="profile.active"
							v-html="profile.props.ORDER_PROP_75.value"></option>
					</select>
				</div>
			</div>
			<div v-bind:class="{'hidden': activeProfile.stores.count == 1}" class="environ__group">
				<label class="environ__label">Активный склад</label>
				<div class="environ__select">
					<select class="select-store select-store-home select" name="">
						<option
							v-for="store in activeProfile.stores.list"
							v-bind:value="store.ID"
							v-bind:class="{'active': store.selected}"
							v-bind:selected="store.selected"
							v-html="store.TITLE"></option>
					</select>
				</div>
			</div>
		</div>

		<div class="bonus-info" v-if="$root.arUser.loyalty" >
			 <div class="bonus-info__header">
				 <h2 class="bonus-info__title">Вы являетесь участником программы “Feron-бонус”</h2>
				 <router-link :to="'/bonus/'" class="bonus-info__read"><span>Подробнее о программе лояльности</span></router-link>
			 </div>
			 <div class="bonus-info__stat">
				 <div class="bonus-info__label">активные бонусы:</div>
				 <div v-html="$root.arUser.balls" class="bonus-info__value"></div>
			 </div>
		</div>

		<component-notify></component-notify>

		<section class="front-stats" v-if="!$root.arUser.subclient">
			<div class="stats-grid row">
				<div class="col-8 col-lg-12" style="position: relative;">
					<component-graph-lines v-bind='graphs.lines' ref="graph-lines"></component-graph-lines>
                    <SettingsGraphSideBar v-if="graphSettingsSidebarVisible" />
				</div>

				<div class="col-4 col-lg-12">
					<div class="row">
						<div class="col-12 col-lg-6 col-sm-12">
							<component-graph-diagram v-bind='graphs.diagram'></component-graph-diagram>
						</div>
						<div class="col-12 col-lg-6 col-sm-12">
							<component-graph-diagram v-bind='graphs.diagram_2'></component-graph-diagram>
						</div>
					</div>
				</div>
			</div>
<!--            <div class="row more-info-row">-->
<!--                <div class="col-8">-->
<!--                    <router-link class="more-info-link" to="NIY">Подробнее</router-link>-->
<!--                </div>-->
<!--                <div class="col-4"/>-->
<!--            </div>-->
		</section>

		<section class="front-popular">
			<component-tabs v-bind="tabsDataHome"></component-tabs>
		</section>

		<section class="front-orders" v-if="!$root.arUser.subclient">
			<div class="orders js-orders" data-source="/data/orders.json">
				<div class="orders__header">
					<h2 class="orders__title">Последние заказы</h2>
					<div class="orders__filter">
						<select class="select-home js-owner-select-home" name="owner">
							<option value="">Все организации</option>
							<option
								v-for="profile in $root.arUser.profiles.list"
								v-bind:value="profile.name"
								v-html="profile.name"></option>
						</select>
					</div>
				</div>
				<div class="orders__table">
					<table class="t-orders">
						<thead>
							<tr>
								<th>№ заказа</th>
								<th><button class="sort" data-field="DATE_INSERT" v-on:click.prevent="setOrderSort">Дата заказа</button></th>
								<th><button class="sort" data-field="PRICE" v-on:click.prevent="setOrderSort">Сумма заказа</button></th>
								<th>Резерв до</th>
								<th width="150"><button class="sort" data-field="STATUS_ID" v-on:click.prevent="setOrderSort">Статус заказа</button></th>
								<th width="28">&nbsp;</th>
							</tr>
						</thead>
						<tbody>
							<tr v-if="showOrders" v-for="order in orders">
								<td>{{order.PROPS.BUH_NUM.VALUE}}</td>
								<td>{{order.DATE_INSERT_FORMAT}}</td>
								<td>{{order.PRICE}}</td>
								<td>{{order.PROPS.srok_rezerva.VALUE}}</td>
								<td><span class="order-status-name" v-bind:style="'color:' + order.STATUS.COLOR">{{order.STATUS.NAME}}</span></td>
								<td>
									<router-link :to="'/orders/order/' + order.ACCOUNT_NUMBER + '/'" class="detail-btn">
										<svg class="arrow" width="7" height="12">
											<use xlink:href="/images/sprite.svg#caret-right"></use>
										</svg>
									</router-link>
								</td>
							</tr>
							<tr v-if="!showOrders">
								<td colspan="6"><span class="empty-orders">Заказы отсутствуют</span></td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="orders__footer">
					<router-link :to="'/orders/'" class="btn">Все заказы</router-link>
				</div>
			</div>
		</section>

		<section class="front-news" v-if="showNews">
			<div class="last-news">
				<div class="last-news__header">
					<h2 class="last-news__title">Последние новости компании</h2>
					<a class="last-news__read" href="https://feron.ru/news/" target="_blank">Читать все новости на сайте
						<svg class="arrow" width="14" height="13">
							<use xlink:href="images/sprite.svg#arrow-right"></use>
						</svg>
					</a>
				</div>
				<div class="last-news__grid">
					<div v-for="newsItem in news.ITEMS" class="last-news__item">
						<div class="news">
							<div class="news__img">
								<img v-bind:src="'https://feron.ru' + newsItem.PREVIEW_PICTURE.SRC" alt="">
							</div>
							<div class="news__name">{{newsItem.NAME}}</div>
							<a v-bind:href="'https://feron.ru' + newsItem.DETAIL_PAGE_URL" target="_blank" class="btn news__read">Подробнее</a>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import graphComponentDiagram from '../components/graphComponentDiagram';
//import graphComponentLines from '../components/graphComponentLines';
import graphComponentLinesNEW from '../components/graphComponentLines_NEW';

import tabsComponent from '../components/tabsComponent';
import notifyComponent from '../components/notifyComponent';
import productSliderComponent from '../components/productSliderComponent';
import SettingsGraphSideBar from "../components/SettingsGraphSideBar";
export default {
	name: 'HomeComponent',
	data() {
		var self = this;
		return {
            name: "home",
			show: false,
			profiles: self.$root.arUser.profiles.list,
			breadcrumbs: [
				{ link: '/', name: 'Главная' },
			],
			graphs: {
				lines: {graph_name: "Статистика закупок", graph_id: "d3-lines", graph_radius: "", graph_source: "ajax/analitic.php?type=sales"},
				diagram: {graph_name: "Средняя сумма заказа", graph_id: "d3-donut-vertical", graph_radius: 50, graph_source: "ajax/analitic.php?type=average"},
				diagram_2: {graph_name: "Ассортимент", graph_id: "d3-donut-vertical-2", graph_radius: 50, graph_source: "ajax/analitic.php?type=assortment"},
			},
			tabsDataHome: {
				title: "Сейчас самое популярное",
				classes: {
					header: 'popular__tabs',
					body: 'popular__body',
				},
				tabsData: {
					tab_lowprice: {
						id: "tab_lowprice",
						name: "Цены снижены",
						html: 'asdasd',
						mode: 'product_slider',
						active: true,
						data: {
							filter: {PROPERTY_SALE: '1', "!=PROPERTY_ARCHIVE": "1", IBLOCK_ID: 56},
							slider_id: "products_lowprice",
							label: "label_red",
							init: true,
						},
					},
					tab_brandnew: {
						id: "tab_brandnew",
						name: "Новинки",
						html: "",
						mode: 'product_slider',
						active: false,
						data: {
							filter: {PROPERTY_NEWPRODUCT: '1', "!=PROPERTY_ARCHIVE": "1", IBLOCK_ID: 56},
							slider_id: "products_brandnew",
							label: "label_green",
							init: false,
						},
					},
					tab_topsales: {
						id: "tab_topsales",
						name: "Лидеры продаж",
						html: "",
						mode: 'product_slider',
						active: false,
						data: {
							filter: {SALELEADER: '1', "!=PROPERTY_ARCHIVE": "1", IBLOCK_ID: 56},
							slider_id: "products_topsales",
							label: "label_false",
							init: false,
						},
					},
				}
			},
			showOrders: true,
			showNews: false,
			orders: [],
			news: [],
			filterOrders: {},
			sortOrders: {by: "ID", order: "DESC"},
            graphSettingsSidebarVisible: false,
		}
	},
	computed: {
		activeProfile() {
			var self = this;
			if(self.$root.arUser.profile) return self.$root.arUser.profiles.active;
			else return {stores: 0};
		},
	},
	components: {
        SettingsGraphSideBar,
		'component-graph-diagram': graphComponentDiagram,
		'component-graph-lines': graphComponentLinesNEW,
		'component-notify': notifyComponent,
		'component-tabs': tabsComponent,
		'component-product-slider': productSliderComponent,
	},
	watch: {
		filterOrders: function(){
			this.loadOrders();
		},
		sortOrders: function(){
			this.loadOrders();
		},
	},
	mounted: function(){
		var self = this;
		self.$root.graphs = self.graphs;
		self.$root.breadcrumbs = self.breadcrumbs;
		self.loadOrders();
		self.loadNews();

		self.$nextTick(function(){

			$('.select').select2({
				minimumResultsForSearch: -1,
				width: "100%",
				selectionCssClass: "select2-results__option--selected",
			}).on('select2:select', function (e) {
				setTimeout(function(){
					var selectedProfileId = $(".select-profile-home option:selected").attr("value");
					var selectedStoreId = $(".select-store-home option:selected").attr("value");
					self.$root.setActiveProfile(selectedProfileId, selectedStoreId);
				}, 300);
			});

			$('.js-owner-select-home').select2({
				minimumResultsForSearch: -1
			}).on("change.select2", function(){
				var owner = $("select[name=owner]").val();
				/**/
				if(owner != "") self.filterOrders = {"PROPERTY_VAL_BY_CODE_COMPANY": owner};
				else self.filterOrders = {};
			});
		});

        this.$on("show-hide-settings-sidebar", ()=>{
            this.graphSettingsSidebarVisible = !this.graphSettingsSidebarVisible;
        });

	},
	methods:{
		loadNews: function(){
			var self = this;
			var url = "https://feron.ru/news/?export=Y";
			$.getJSON(url, function(json){
				self.news = json;
				self.showNews = true;
			});
		},
		loadOrders: function(){
			var self = this;
			var sortBy = self.sortOrders.by;
			var data = {
				"filter": self.filterOrders,
				"nav": {"nPageSize": 10, "iNumPage": 1 }
			};

			data.sort = {};
			data.sort[sortBy] = self.sortOrders.order;
			$.getJSON("/data/components/orders.php", data, function(json){
				if(json.status) {
					self.orders = json.orders;
					self.showOrders = self.orders.length > 0;
				}
			});
		 },
		 setOrderSort(e){
			var self = this;
			var sortByParam = e.target.dataset.field;
			var sortOrder = "asc";

			if(self.sortOrders.by == sortByParam && self.sortOrders.order == "asc") sortOrder = "desc";
			else if(self.sortOrders.by == sortByParam && self.sortOrders.order == "desc")sortOrder = "asc";

			self.sortOrders = {by: sortByParam, order: sortOrder};

			$(".sort").removeClass("sort_asc sort_desc");
			$(e.target).addClass("sort_" + sortOrder);
		 },
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.order-status-name{line-height: 11px;display: block;}
.select2-container--default .select2-results__option--selected {
    background-color: #ddd;
}
.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
    background-color: #3363C9;
    color: white;
}
.select2-results__option--selectable {
    cursor: pointer;
}
.empty-orders{
	display: block;
    text-align: center;
    padding: 20px;
    font-size: 14px;
}

.orders__table th button.sort_desc:before {
    background: transparent url(/images/sort_asc.svg) no-repeat center center;
}
.orders__table th button.sort_asc:before {
    background: transparent url(/images/sort_desc.svg) no-repeat center center;
}
.more-info-link {
    display: flex;
    justify-content: flex-end;
    color: #0a58ca;
}

.more-info-row {
    width: 100%;
    margin: -1em 0;

}
</style>
