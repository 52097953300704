var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "environ" }, [
        _c(
          "div",
          {
            staticClass: "environ__group",
            class: { hidden: _vm.$root.arUser.profiles.count == 1 }
          },
          [
            _c("label", { staticClass: "environ__label" }, [
              _vm._v("Активный договор")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "environ__select environ__select--order" },
              [
                _c(
                  "select",
                  {
                    staticClass: "select-profile select-profile-home select",
                    attrs: { name: "" }
                  },
                  _vm._l(_vm.$root.arUser.profiles.list, function(profile) {
                    return _c("option", {
                      class: { active: profile.active },
                      domProps: {
                        value: profile.id,
                        selected: profile.active,
                        innerHTML: _vm._s(profile.props.ORDER_PROP_75.value)
                      }
                    })
                  }),
                  0
                )
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "environ__group",
            class: { hidden: _vm.activeProfile.stores.count == 1 }
          },
          [
            _c("label", { staticClass: "environ__label" }, [
              _vm._v("Активный склад")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "environ__select" }, [
              _c(
                "select",
                {
                  staticClass: "select-store select-store-home select",
                  attrs: { name: "" }
                },
                _vm._l(_vm.activeProfile.stores.list, function(store) {
                  return _c("option", {
                    class: { active: store.selected },
                    domProps: {
                      value: store.ID,
                      selected: store.selected,
                      innerHTML: _vm._s(store.TITLE)
                    }
                  })
                }),
                0
              )
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _vm.$root.arUser.loyalty
        ? _c("div", { staticClass: "bonus-info" }, [
            _c(
              "div",
              { staticClass: "bonus-info__header" },
              [
                _c("h2", { staticClass: "bonus-info__title" }, [
                  _vm._v("Вы являетесь участником программы “Feron-бонус”")
                ]),
                _vm._v(" "),
                _c(
                  "router-link",
                  { staticClass: "bonus-info__read", attrs: { to: "/bonus/" } },
                  [_c("span", [_vm._v("Подробнее о программе лояльности")])]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "bonus-info__stat" }, [
              _c("div", { staticClass: "bonus-info__label" }, [
                _vm._v("активные бонусы:")
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass: "bonus-info__value",
                domProps: { innerHTML: _vm._s(_vm.$root.arUser.balls) }
              })
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("component-notify"),
      _vm._v(" "),
      !_vm.$root.arUser.subclient
        ? _c("section", { staticClass: "front-stats" }, [
            _c("div", { staticClass: "stats-grid row" }, [
              _c(
                "div",
                {
                  staticClass: "col-8 col-lg-12",
                  staticStyle: { position: "relative" }
                },
                [
                  _c(
                    "component-graph-lines",
                    _vm._b(
                      { ref: "graph-lines" },
                      "component-graph-lines",
                      _vm.graphs.lines,
                      false
                    )
                  ),
                  _vm._v(" "),
                  _vm.graphSettingsSidebarVisible
                    ? _c("SettingsGraphSideBar")
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-4 col-lg-12" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 col-lg-6 col-sm-12" },
                    [
                      _c(
                        "component-graph-diagram",
                        _vm._b(
                          {},
                          "component-graph-diagram",
                          _vm.graphs.diagram,
                          false
                        )
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-12 col-lg-6 col-sm-12" },
                    [
                      _c(
                        "component-graph-diagram",
                        _vm._b(
                          {},
                          "component-graph-diagram",
                          _vm.graphs.diagram_2,
                          false
                        )
                      )
                    ],
                    1
                  )
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "front-popular" },
        [
          _c(
            "component-tabs",
            _vm._b({}, "component-tabs", _vm.tabsDataHome, false)
          )
        ],
        1
      ),
      _vm._v(" "),
      !_vm.$root.arUser.subclient
        ? _c("section", { staticClass: "front-orders" }, [
            _c(
              "div",
              {
                staticClass: "orders js-orders",
                attrs: { "data-source": "/data/orders.json" }
              },
              [
                _c("div", { staticClass: "orders__header" }, [
                  _c("h2", { staticClass: "orders__title" }, [
                    _vm._v("Последние заказы")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "orders__filter" }, [
                    _c(
                      "select",
                      {
                        staticClass: "select-home js-owner-select-home",
                        attrs: { name: "owner" }
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("Все организации")
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.$root.arUser.profiles.list, function(
                          profile
                        ) {
                          return _c("option", {
                            domProps: {
                              value: profile.name,
                              innerHTML: _vm._s(profile.name)
                            }
                          })
                        })
                      ],
                      2
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "orders__table" }, [
                  _c("table", { staticClass: "t-orders" }, [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v("№ заказа")]),
                        _vm._v(" "),
                        _c("th", [
                          _c(
                            "button",
                            {
                              staticClass: "sort",
                              attrs: { "data-field": "DATE_INSERT" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.setOrderSort.apply(null, arguments)
                                }
                              }
                            },
                            [_vm._v("Дата заказа")]
                          )
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _c(
                            "button",
                            {
                              staticClass: "sort",
                              attrs: { "data-field": "PRICE" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.setOrderSort.apply(null, arguments)
                                }
                              }
                            },
                            [_vm._v("Сумма заказа")]
                          )
                        ]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Резерв до")]),
                        _vm._v(" "),
                        _c("th", { attrs: { width: "150" } }, [
                          _c(
                            "button",
                            {
                              staticClass: "sort",
                              attrs: { "data-field": "STATUS_ID" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.setOrderSort.apply(null, arguments)
                                }
                              }
                            },
                            [_vm._v("Статус заказа")]
                          )
                        ]),
                        _vm._v(" "),
                        _c("th", { attrs: { width: "28" } }, [_vm._v(" ")])
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      [
                        _vm._l(_vm.orders, function(order) {
                          return _vm.showOrders
                            ? _c("tr", [
                                _c("td", [
                                  _vm._v(_vm._s(order.PROPS.BUH_NUM.VALUE))
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(_vm._s(order.DATE_INSERT_FORMAT))
                                ]),
                                _vm._v(" "),
                                _c("td", [_vm._v(_vm._s(order.PRICE))]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(_vm._s(order.PROPS.srok_rezerva.VALUE))
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "order-status-name",
                                      style: "color:" + order.STATUS.COLOR
                                    },
                                    [_vm._v(_vm._s(order.STATUS.NAME))]
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "detail-btn",
                                        attrs: {
                                          to:
                                            "/orders/order/" +
                                            order.ACCOUNT_NUMBER +
                                            "/"
                                        }
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass: "arrow",
                                            attrs: { width: "7", height: "12" }
                                          },
                                          [
                                            _c("use", {
                                              attrs: {
                                                "xlink:href":
                                                  "/images/sprite.svg#caret-right"
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ])
                            : _vm._e()
                        }),
                        _vm._v(" "),
                        !_vm.showOrders ? _c("tr", [_vm._m(0)]) : _vm._e()
                      ],
                      2
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "orders__footer" },
                  [
                    _c(
                      "router-link",
                      { staticClass: "btn", attrs: { to: "/orders/" } },
                      [_vm._v("Все заказы")]
                    )
                  ],
                  1
                )
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showNews
        ? _c("section", { staticClass: "front-news" }, [
            _c("div", { staticClass: "last-news" }, [
              _c("div", { staticClass: "last-news__header" }, [
                _c("h2", { staticClass: "last-news__title" }, [
                  _vm._v("Последние новости компании")
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "last-news__read",
                    attrs: { href: "https://feron.ru/news/", target: "_blank" }
                  },
                  [
                    _vm._v("Читать все новости на сайте\n\t\t\t\t\t\t"),
                    _c(
                      "svg",
                      {
                        staticClass: "arrow",
                        attrs: { width: "14", height: "13" }
                      },
                      [
                        _c("use", {
                          attrs: {
                            "xlink:href": "images/sprite.svg#arrow-right"
                          }
                        })
                      ]
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "last-news__grid" },
                _vm._l(_vm.news.ITEMS, function(newsItem) {
                  return _c("div", { staticClass: "last-news__item" }, [
                    _c("div", { staticClass: "news" }, [
                      _c("div", { staticClass: "news__img" }, [
                        _c("img", {
                          attrs: {
                            src:
                              "https://feron.ru" + newsItem.PREVIEW_PICTURE.SRC,
                            alt: ""
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "news__name" }, [
                        _vm._v(_vm._s(newsItem.NAME))
                      ]),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "btn news__read",
                          attrs: {
                            href: "https://feron.ru" + newsItem.DETAIL_PAGE_URL,
                            target: "_blank"
                          }
                        },
                        [_vm._v("Подробнее")]
                      )
                    ])
                  ])
                }),
                0
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { colspan: "6" } }, [
      _c("span", { staticClass: "empty-orders" }, [
        _vm._v("Заказы отсутствуют")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }